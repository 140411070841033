<template>
  <a-modal
      :width="600"
      centered
      v-model="showPopup"
      title="修改地址信息"
      @ok='handleConfirmAddress'
      @cancel='handleCancelAddress'
  >
    <div class="flex-start-center">
      <span>省市区：</span>
      <a-cascader
          class="w-400"
          :value="defaultList"
          :options="cityOptions"
          placeholder="请选择省市区"
          :popupStyle="{height: '400px'}"
          @change="handleChangeArea"
      />
    </div>
    <div class="flex-start-center mt-10">
      <span>详情地址：</span>
      <a-input
          allowClear
          v-model="addressDetailInfoText"
          class="w-400"
          placeholder='输入详情地址'
      ></a-input>
    </div>
  </a-modal>
</template>
<script>
import {cityOptions} from "@/untils/cityOption"
export default {
  emits: ['edit'],
  data() {
    return {
      showPopup: false,
      cityOptions,
      defaultList: [],
      addressDetailInfoText: '',
      addressInfo: {
        province: '',
        city: '',
        county: ''
      }
    }
  },
  methods: {
    show() {
      this.showPopup = true
    },
    /** 确定修改 */
    handleConfirmAddress() {
      if (this.defaultList.length <= 0) return this.$message.warn('请选择省市区')
      if (!this.addressDetailInfoText) return this.$message.warn('请输入地址详情')
      this.addressInfo.province = this.defaultList[0]
      this.addressInfo.city = this.defaultList[1]
      if (this.defaultList[2]) {
        this.addressInfo.county = this.defaultList[2]
      }
      const tempAddress = this.addressInfo.province + this.addressInfo.city + this.addressInfo.county + this.addressDetailInfoText
      this.$emit('edit', tempAddress)
      this.handleCancelAddress()
    },
    /** 取消修改 */
    handleCancelAddress() {
      this.defaultList = []
      this.addressDetailInfoText = ''
      this.addressInfo = {
        province: '',
        city: '',
        county: ''
      }
      this.showPopup = false
    },
    /** 改变省市区 */
    handleChangeArea(e) {
      this.defaultList = e
    }
  }
}
</script>
<style scoped lang="scss">
.change-address-info {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>