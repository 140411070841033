var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-box" },
    [
      _c(
        "a-modal",
        {
          attrs: { width: 700, centered: "", title: "修改物流信息" },
          on: { ok: _vm.handleConfirmChangeMessage, cancel: _vm.cancel },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center"
              }
            },
            [
              _c(
                "div",
                { staticClass: "flex-start-center" },
                [
                  _c("span", [_vm._v("收件人：")]),
                  _c("a-input", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      disabled: _vm.orderType === "rateService",
                      allowClear: "",
                      placeholder: "输入收件人姓名"
                    },
                    model: {
                      value: _vm.params.consigneeRealname,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "consigneeRealname", $$v)
                      },
                      expression: "params.consigneeRealname"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "flex-start-center ml-20" },
                [
                  _c("span", [_vm._v("收件人电话：")]),
                  _c("a-input", {
                    staticStyle: { width: "150px" },
                    attrs: {
                      disabled: _vm.orderType === "rateService",
                      allowClear: "",
                      placeholder: "输入收件人电话"
                    },
                    model: {
                      value: _vm.params.consigneeTelphone,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "consigneeTelphone", $$v)
                      },
                      expression: "params.consigneeTelphone"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center"
              }
            },
            [
              _c("span", [_vm._v("详情地址：")]),
              _c("a-input", {
                staticStyle: { width: "430px", "margin-top": "10px" },
                attrs: {
                  allowClear: "",
                  disabled: true,
                  placeholder: "输入详情地址"
                },
                model: {
                  value: _vm.params.consigneeAddress,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "consigneeAddress", $$v)
                  },
                  expression: "params.consigneeAddress"
                }
              }),
              _vm.orderType !== "rateService"
                ? _c(
                    "a-button",
                    {
                      staticClass: "ml-10 mt-10",
                      attrs: { type: "danger", size: "small" },
                      on: { click: _vm.handleShowEditorAddressInfo }
                    },
                    [_vm._v("编辑地址")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.currentData.expressNo
            ? _c(
                "div",
                { staticStyle: { display: "flex", "margin-top": "10px" } },
                [
                  _c("span", [_vm._v("物流单号：")]),
                  _c("a-input", {
                    staticStyle: { width: "230px" },
                    attrs: { allowClear: "", placeholder: "输入物流单号" },
                    model: {
                      value: _vm.params.expressNo,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "expressNo", $$v)
                      },
                      expression: "params.expressNo"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm.currentData.logisticsId
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-start",
                    "align-items": "center"
                  }
                },
                [
                  _c("span", [_vm._v("物流公司：")]),
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "300px", "margin-top": "10px" },
                      attrs: { allowClear: "", placeholder: "请选择物流公司" },
                      model: {
                        value: _vm.params.logisticsId,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "logisticsId", $$v)
                        },
                        expression: "params.logisticsId"
                      }
                    },
                    _vm._l(_vm.logisticsCompanyList, function(item, key) {
                      return _c(
                        "a-select-option",
                        { key: key, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.companyName))]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("ChangeAddressInfo", {
        ref: "changeAddressInfoEl",
        on: {
          edit: function($event) {
            return _vm.handleEditorAddressInfo($event)
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }